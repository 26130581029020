/* eslint-disable */
import httpRequest from '../../../util/Request'

// 获取自己的机器列表
export function apiGetHaveToList (userId) {
  return httpRequest({
    url: '/api/index/zyMachinelist',
    method: 'post',
    data: { user_id: userId }
  })
}

// 添加今日必去机器
export function apiPostHaveTo (userId, machineIds) {
  return httpRequest({
    url: '/api/index/addmustGo',
    method: 'post',
    data: {
      user_id: userId,
      machine_id: machineIds
    }
  })
}
