<template>
  <div class="container">
    <Header class="header" style="margin-top: 1.5rem"/>
    <div class="content">
      <div class="select">
        <a-select
            v-model:value="data.selectedItems"
            mode="multiple"
            placeholder="请选择 今日必去机器"
            style="width: 80%"
            :options="filteredOptions.map(item => ({
              value: item.machine_code,
              label: item.machine_name
            }))"
        ></a-select>
      </div>
      <div class="btn-operate">
        <a-button class="btn btn-next" type="primary" block @click="handleNextStep">下一步</a-button>
        <a-button class="btn" block>
          <router-link to="/">返回首页</router-link>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useCreateStore } from '../../store'
import { message } from 'ant-design-vue'
import Header from '../../components/Header/Header.vue'
import { apiPostHaveTo, apiGetHaveToList } from '../../request/apis/HaveTo/HaveToApi'

export default defineComponent({
  name: 'HaveTo',
  components: { Header },
  setup () {
    const store = useCreateStore()
    const router = useRouter()
    const data = reactive({
      userId: store.state.user.user_id,
      selectList: [],
      selectedItems: ref([])
    })
    const filteredOptions = computed(() => {
      return data.selectList.filter(o => {
        return !data.selectedItems.includes(o.machine_name)
      })
    })
    onMounted(() => {
      apiGetHaveToList(data.userId).then((res) => {
        data.selectList = res.data.data
      })
    })

    const handleNextStep = () => {
      apiPostHaveTo(data.userId, data.selectedItems).then(res => {
        if (res.data.code === '0') {
          // 设置今日必去，并返回任务列表
          store.dispatch('setHaveToId', data.selectedItems)
          router.push({ name: 'TaskList' })
          message.success('成功选择今日必去')
        } else {
          message.error('提交必去机器失败')
        }
      })
    }

    return {
      data,
      filteredOptions,
      handleNextStep
    }
  }
})
</script>

<style scoped lang="scss">
.container {
  height: 100vh;
  overflow-y: hidden;
  background: url("../../assets/background.png") no-repeat top;
  background-size: cover;
}
.content {
  text-align: center;

  .btn-operate {
    width: 100%;
    position: fixed;
    bottom: 1rem;
    left: 0;

    .btn {
      width: 80%;
      height: 1rem;
      border: 1px solid rgba(27, 164, 42, 40%);
    }
    .btn-next {
      /*background-color: #6A6BC5;*/
    }
  }
}
</style>
